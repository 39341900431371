var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("template-card", {
    attrs: { name: _vm.name, icon: "$paper_rectangle" },
    on: {
      "dragend:item": function($event) {
        return _vm.addItemDrop()
      },
      "click:item": function($event) {
        return _vm.addItem()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }